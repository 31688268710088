export const statuses = {
    CONVERTING: 0,
    UPLOADING: 1,
    SUCCESS: 2,
    CONVERTING_FAILED: 3,
    UPLOADING_FAILED: 4,
};

export class AnimaticFile {
    constructor(file) {
        if (file instanceof File) {
            this.file = file;

            this.info = null;
            this.task = null;
            this.valid = false;

            this.mp4Bytes = null;
            this.jpgBytes = null;
            this.jpgFile = null;
            this.aviBytes = null;
            this.aviFile = null;
            this.wavBytes = null;
            this.wavFile = null;
        } else {
            throw TypeError("file argument is mandatory and must be instance of 'File'");
        }
    }
}

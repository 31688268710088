import { v4 as uuidv4 } from "uuid";
import { fetchFile } from "@ffmpeg/ffmpeg";
import { operation } from "ftrack-javascript-api";

import { statuses } from "./models.js";

class Job {
    constructor(file, scp, sup, sm, ss) {
        // These are abbreviated names of callbacks
        this.file = file;
        this.scp = scp;
        this.sup = sup;
        this.sm = sm;
        this.ss = ss;
    }
}

export class JobQueue {
    constructor(ffmpeg, mediainfo, session) {
        this.ffmpeg = ffmpeg;
        this.mediainfo = mediainfo;
        this.session = session;

        this.jobs = [];
        this.failedJobs = {};

        this.interval = setTimeout(this.execute.bind(this), 300);
    }

    push(file, setConvertingProgress, setUploadingProgress, setMessage, setStatus) {
        this.jobs.push(
            new Job(file, setConvertingProgress, setUploadingProgress, setMessage, setStatus)
        );
    }

    async execute() {
        let job = this.jobs.shift();

        if (job) {
            if (await this.convert(job)) {
                this.upload(job);
            }
        }
        setTimeout(this.execute.bind(this), 300);
    }

    async convert(job) {
        const step = 100 / 12;
        const readChunk = (chunkSize, offset) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event.target.error) reject(event.target.error);
                    // @ts-ignore
                    resolve(new Uint8Array(event.target.result));
                };
                reader.readAsArrayBuffer(job.file.file.slice(offset, offset + chunkSize));
            });
        };

        let progress = 0;

        try {
            job.file.info = await this.mediainfo.analyzeData(() => job.file.file.size, readChunk);
            progress += step;
            job.scp(progress);

            job.sm(
                `Frames: ${Math.round(
                    job.file.info.media.track[0].Duration * job.file.info.media.track[0].FrameRate
                )}
                Bytes: ${job.file.file.size}`
            );

            job.file.mp4Bytes = await fetchFile(job.file.file);
            progress += step;
            job.scp(progress);

            this.ffmpeg.FS("writeFile", job.file.file.name, job.file.mp4Bytes);
            progress += step;
            job.scp(progress);

            await this.ffmpeg.run(
                "-i",
                job.file.file.name,
                "-y",
                "-an",
                "-f",
                "image2",
                "-vframes",
                "1",
                "-ss",
                "0",
                job.file.file.name.split(".")[0] + ".jpg",
                "-q:v",
                "4"
            );
            progress += step;
            job.scp(progress);

            job.file.jpgBytes = this.ffmpeg.FS(
                "readFile",
                job.file.file.name.split(".")[0] + ".jpg"
            );
            progress += step;
            job.scp(progress);

            job.file.jpgFile = new File(
                [job.file.jpgBytes],
                job.file.file.name.split(".")[0] + ".jpg",
                { type: "image/jpeg" }
            );
            progress += step;
            job.scp(progress);

            await this.ffmpeg.run(
                "-i",
                job.file.file.name,
                "-acodec",
                "pcm_s16le",
                "-vcodec",
                "mjpeg",
                "-q:v",
                "23",
                "-s",
                "960x540",
                "-y",
                job.file.file.name.split(".")[0] + ".avi"
            );
            progress += step;
            job.scp(progress);

            job.file.aviBytes = this.ffmpeg.FS(
                "readFile",
                job.file.file.name.split(".")[0] + ".avi"
            );
            progress += step;
            job.scp(progress);

            job.file.aviFile = new File(
                [job.file.aviBytes],
                job.file.file.name.split(".")[0] + ".avi",
                { type: "video/x-msvideo" }
            );
            progress += step;
            job.scp(progress);

            await this.ffmpeg.run(
                "-i",
                job.file.file.name.split(".")[0] + ".avi",
                "-vn",
                "-acodec",
                "copy",
                job.file.file.name.split(".")[0] + ".wav"
            );
            progress += step;
            job.scp(progress);

            try {
                job.file.wavBytes = this.ffmpeg.FS(
                    "readFile",
                    job.file.file.name.split(".")[0] + ".wav"
                );
                job.file.wavFile = new File(
                    [job.file.wavBytes],
                    job.file.file.name.split(".")[0] + ".wav",
                    { type: "audio/wav" }
                );
                progress += step;
                job.scp(progress);
            } catch (error) {
                job.file.wavBytes = null;
                job.file.wavFile = null;
            }

            job.scp(100);

            return true;
        } catch (error) {
            job.sm(String(error));
            job.ss(statuses.CONVERTING_FAILED);

            return false;
        }
    }

    async upload(job) {
        const versionId = uuidv4();
        const step = 100 / 16;

        let thumbnailResponse = null;
        let createOperations = [];
        let reviewResponse = null;
        let statusResponse = null;
        let progress = 0;
        let response = null;
        let assetId = uuidv4();

        job.ss(statuses.UPLOADING);

        try {
            let assetTypeResponse = await this.session.query(
                "select id from AssetType where name is 'Animatic'"
            );
            progress += step;
            job.sup(progress);

            let assetResponse = await this.session.query(
                `select id from Asset
                where context_id is "${job.file.task.parent.id}"
                and type_id is "${assetTypeResponse.data[0].id}"
                and name is "${job.file.file.name.split(".")[0]}" limit 1`
            );
            progress += step;
            job.sup(progress);

            if (assetResponse.data.length && assetResponse.data[0]) {
                assetId = assetResponse.data[0].id;
            } else {
                createOperations.push(
                    operation.create("Asset", {
                        id: assetId,
                        name: job.file.file.name.split(".")[0],
                        context_id: job.file.task.parent.id,
                        type_id: assetTypeResponse.data[0].id,
                    })
                );
            }
            progress += step;
            job.sup(progress);

            thumbnailResponse = await this.session.createComponent(job.file.jpgFile);
            progress += step;
            job.sup(progress);

            statusResponse = await this.session.query(
                "select id from Status where name is 'Approved'"
            );
            progress += step;
            job.sup(progress);

            await this.session.update("Task", [job.file.task.id], {
                thumbnail_id: thumbnailResponse[0].data.id,
                status_id: statusResponse.data[0].id,
            });
            progress += step;
            job.sup(progress);

            createOperations.push(
                operation.create("AssetVersion", {
                    id: versionId,
                    thumbnail_id: thumbnailResponse[0].data.id,
                    asset_id: assetId,
                    status_id: statusResponse.data[0].id,
                    task_id: job.file.task.id,
                    comment: "Created by Animatic Uploader",
                    is_published: false,
                })
            );
            progress += step;
            job.sup(progress);

            await this.session.call(createOperations);
            progress += step;
            job.sup(progress);

            reviewResponse = await this.session.createComponent(job.file.file, {
                data: { name: "ftrackreview-mp4", version_id: versionId },
            });
            progress += step;
            job.sup(progress);

            await this.session.create("Metadata", {
                key: "ftr_meta",
                parent_id: reviewResponse[0].data.id,
                parent_type: "FileComponent",
                value: JSON.stringify({
                    format: "h264",
                    frameIn: 0,
                    frameOut: Math.round(
                        job.file.info.media.track[0].Duration *
                            job.file.info.media.track[0].FrameRate
                    ),
                    frameRate: job.file.info.media.track[0].FrameRate,
                }),
            });
            progress += step;
            job.sup(progress);

            await this.session.update("FileComponent", [reviewResponse[0].data.id], {
                metadata: [
                    {
                        __entity_type__: "Metadata",
                        key: "ftr_meta",
                        parent_id: reviewResponse[0].data.id,
                    },
                ],
            });
            progress += step;
            job.sup(progress);

            await this.session.createComponent(job.file.aviFile, {
                data: { name: "media", version_id: versionId },
            });
            progress += step;
            job.sup(progress);
            if (job.file.wavFile != null) {
                await this.session.createComponent(job.file.wavFile, {
                    data: { name: "audio", version_id: versionId },
                });
            }
            progress += step;
            job.sup(progress);

            response = await this.session.query(
                `select configuration_id, entity_id from ContextCustomAttributeValue
                where entity_id is "${job.file.task.parent.id}" and key is "fend"`
            );
            progress += step;
            job.sup(progress);

            await this.session.update(
                "ContextCustomAttributeValue",
                [response.data[0].configuration_id, job.file.task.parent.id],
                {
                    value: Math.round(
                        job.file.info.media.track[0].Duration *
                            job.file.info.media.track[0].FrameRate
                    ),
                }
            );
            progress += step;
            job.sup(progress);

            await this.session.update("AssetVersion", [versionId], { is_published: true });
            job.sup(100);

            job.ss(statuses.SUCCESS);
        } catch (error) {
            job.sm(String(error));
            job.ss(statuses.UPLOADING_FAILED);

            this.failedJobs[job.file.file.name] = job;
        }
    }
}

import React, { useMemo } from "react";
import { Tabs, Tab } from "react-bootstrap";

export default function AnimaticUploaderHelpTab() {
    const tabsStyle = useMemo(() => {
        return { marginLeft: "5%", marginRight: "5%", marginTop: "1%" };
    }, []);
    const tabStyle = useMemo(() => {
        return { textAlign: "center", marginLeft: "5%", marginRight: "5%", marginTop: "1%" };
    }, []);

    return (
        <Tabs defaultActiveKey="Description" id="uncontrolled-tab-example" style={tabsStyle}>
            <Tab eventKey="Description" title="Description">
                <div style={tabStyle}>
                    <p>
                        This action allows you to select multiple files with correct name and in
                        .mp4 format. Correct name means that your file's name is the same as
                        corresponding Shot name in ftrack. The files will be converted to different
                        formats. After converting is done the uploading process will start. This
                        make take a while. Converting and uploading time depends on file's size.
                        Just wait a little bit.
                    </p>
                </div>
            </Tab>
            <Tab eventKey="Usage" title="Usage">
                <div style={tabStyle}>
                    <p>
                        You can drag and drop your files OR click on the dragzone and select files
                        manually
                    </p>
                </div>
            </Tab>
            <Tab eventKey="Support" title="Support">
                <div style={tabStyle}>
                    <p>If something went wrong you can contact support team in Telegram</p>
                </div>
            </Tab>
        </Tabs>
    );
}

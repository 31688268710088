import React, { useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Container, CardGroup, Row } from "react-bootstrap";

import { AnimaticUploaderValidFileCard, AnimaticUploaderBadFileCard } from "./card.js";
import { AnimaticFile } from "./models.js";
import { JobQueue } from "./job_queue.js";

export default function AnimaticUploaderDragzone(props) {
    const [validFiles, setValidFiles] = useState([]);
    const [badFiles, setBadFiles] = useState([]);

    const jobQueue = useMemo(() => {
        return new JobQueue(props.ffmpeg, props.mediainfo, props.session);
    }, [props.ffmpeg, props.mediainfo, props.session]);

    const onDrop = useCallback(async (acceptedFiles) => {
        let validInputFiles = [];
        let badInputFiles = [];
        let validRow = [];
        let badRow = [];

        setValidFiles([]);
        setBadFiles([]);

        for (let i = 0; i < acceptedFiles.length; ++i) {
            let file = new AnimaticFile(acceptedFiles[i]);

            if (validRow.length === 3) {
                validInputFiles.push(validRow);
                validRow = [];
            }
            if (badRow.length === 3) {
                badInputFiles.push(badRow);
                badRow = [];
            }

            for (let i = 0; i < props.animatics.length; ++i) {
                const task = props.animatics[i];

                if (file.file.name.split(".")[0] === task.parent.name) {
                    file.valid = true;
                    file.task = task;

                    for (let row = 0; row < validInputFiles.length && file.valid; ++row) {
                        for (let col = 0; col < validInputFiles[row].length && file.valid; ++col) {
                            if (file.file.name === validInputFiles[row][col].file.name) {
                                file.valid = false;
                                file.task = null;
                            }
                        }
                    }

                    break;
                }
            }
            if (file.valid) {
                validRow.push(file);
            } else {
                badRow.push(file);
            }
        }

        if (validRow.length !== 0) {
            validInputFiles.push(validRow);
        }
        if (badRow.length !== 0) {
            badInputFiles.push(badRow);
        }

        await setValidFiles(validInputFiles);
        await setBadFiles(badInputFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: "video/mp4",
    });

    const style = useMemo(
        () => ({
            ...{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "30px",
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
            },
            ...(isDragActive ? { borderColor: "#2196f3" } : {}),
            ...(isDragAccept ? { borderColor: "#00e676" } : {}),
            ...(isDragReject ? { borderColor: "#ff1744" } : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <div style={{ marginLeft: "5%", marginRight: "5%" }}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <Container style={{ marginTop: "2%" }}>
                {validFiles.map((row, index) => {
                    return (
                        <Row>
                            <CardGroup style={{ height: "100%", width: "100%" }}>
                                {row.map((file) => {
                                    return (
                                        <AnimaticUploaderValidFileCard
                                            key={file.file.name}
                                            file={file}
                                            jobQueue={jobQueue}
                                        />
                                    );
                                })}
                            </CardGroup>
                        </Row>
                    );
                })}
            </Container>
            {validFiles.length && badFiles.length ? <hr style={{ marginTop: "2%" }} /> : null}
            <aside style={{ marginTop: "2%" }}>
                <Container>
                    {badFiles.map((row, index) => {
                        return (
                            <Row>
                                <CardGroup style={{ height: "100%", width: "100%" }}>
                                    {row.map((file) => {
                                        return (
                                            <AnimaticUploaderBadFileCard
                                                key={file.file.name}
                                                file={file}
                                                jobQueue={jobQueue}
                                            />
                                        );
                                    })}
                                </CardGroup>
                            </Row>
                        );
                    })}
                </Container>
            </aside>
        </div>
    );
}

import React from "react";
import ReactDOM from "react-dom";
import { Spinner } from "react-bootstrap";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import { Session } from "ftrack-javascript-api";
import "bootstrap/dist/css/bootstrap.min.css";

import AnimaticUploaderHelpTab from "./help_tab.js";
import AnimaticUploaderDragzone from "./dropzone.js";

function main() {
    ReactDOM.render(
        <Spinner
            animation="grow"
            variant="primary"
            style={{ position: "absolute", left: "50%", top: "50%" }}
        />,
        document.getElementById("root")
    );

    const searchParams = new URLSearchParams(window.location.search);

    let selectionID = searchParams.get("selectionID");
    let serverURL = searchParams.get("serverURL");
    let apiUser = searchParams.get("apiUser");
    let apiKey = searchParams.get("apiKey");

    if (!selectionID || !serverURL || !apiUser || !apiKey) {
        window.addEventListener(
            "message",
            (message) => {
                if (message.data && message.data.topic === "ftrack.widget.load") {
                    let data = message.data.data;

                    let session = new Session(
                        data.credentials.serverUrl,
                        data.credentials.apiUser,
                        data.credentials.apiKey
                    );

                    session.initializing
                        .then(() => {
                            return session.query(
                                `select object_type.name from TypedContext
                            where id is ${data.selection[0]["id"]}`
                            );
                        })
                        .then((response) => {
                            const selectionID = btoa(response.data[0].id);
                            const serverURL = btoa(data.credentials.serverUrl);
                            const apiUser = btoa(data.credentials.apiUser);
                            const apiKey = btoa(data.credentials.apiKey);

                            window.open(
                                `https://animatic-uploader.yarko.com/index.html?selectionID=${selectionID}&serverURL=${serverURL}&apiUser=${apiUser}&apiKey=${apiKey}`,
                                "_blank"
                            );
                        })
                        .catch((reason) => {
                            ReactDOM.render(
                                <React.Fragment>
                                    <h1>ERROR</h1>
                                    <p>{reason}</p>
                                </React.Fragment>,
                                document.getElementById("root")
                            );
                        });
                }
            },
            false
        );
        window.parent.postMessage({ topic: "ftrack.widget.ready" }, "https://ftrack.yarko.com");
        return;
    }

    selectionID = atob(selectionID);
    serverURL = atob(serverURL);
    apiUser = atob(apiUser);
    apiKey = atob(apiKey);

    const session = new Session(serverURL, apiUser, apiKey);
    const ffmpeg = createFFmpeg({ log: true });

    session.initializing
        .then(() => {
            // @ts-ignore
            return session.query(
                `select id, parent.id, parent.name from Task where type.name
                    is "Animatic" and parent.parent.parent_id is ${selectionID}
                    order by parent.name`
            );
        })
        .then((response) => {
            return ffmpeg.load().then(() => {
                return response;
            });
        })
        .then((response) => {
            // @ts-ignore
            return window.MediaInfo().then((mediainfo) => {
                return [response, mediainfo];
            });
        })
        .then(([response, mediainfo]) => {
            ReactDOM.render(
                <React.Fragment>
                    <AnimaticUploaderHelpTab />
                    <AnimaticUploaderDragzone
                        ffmpeg={ffmpeg}
                        session={session}
                        animatics={response.data}
                        mediainfo={mediainfo}
                    />
                </React.Fragment>,
                document.getElementById("root")
            );
        })
        .catch((reason) => {
            ReactDOM.render(
                <React.Fragment>
                    <h1>ERROR</h1>
                    <p>{reason}</p>
                </React.Fragment>,
                document.getElementById("root")
            );
        });
}

window.addEventListener("DOMContentLoaded", main);

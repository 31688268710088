import React, { useState, useEffect } from "react";
import { Button, Card, ProgressBar, Alert } from "react-bootstrap";

import { statuses } from "./models.js";

export function AnimaticUploaderValidFileCard(props) {
    const [convertingProgress, setConvertingProgress] = useState(0);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [message, setMessage] = useState("Processing...");
    const [status, setStatus] = useState(statuses.CONVERTING);

    const convertingFailedCallback = () => {
        setConvertingProgress(0);
        setUploadingProgress(0);
        setMessage("Processing...");
        setStatus(statuses.CONVERTING);

        if (props.jobQueue && props.file) {
            props.jobQueue.push(
                props.file,
                setConvertingProgress,
                setUploadingProgress,
                setMessage,
                setStatus
            );
        }
    };
    const uploadingFailedCallback = () => {
        if (props.jobQueue.failedJobs[props.file.file.name]) {
            setUploadingProgress(0);
            setStatus(statuses.UPLOADING);

            props.jobQueue.upload(props.jobQueue.failedJobs[props.file.file.name]);
        }
    };

    useEffect(() => {
        if (props.jobQueue && props.file) {
            props.jobQueue.push(
                props.file,
                setConvertingProgress,
                setUploadingProgress,
                setMessage,
                setStatus
            );
        }
    }, []);

    return (
        <Card>
            <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>{props.file.file.name}</Card.Title>
                <Card.Text style={{ textAlign: "center" }}>{message}</Card.Text>
            </Card.Body>
            <Card.Footer>
                {status === statuses.CONVERTING ? (
                    <ProgressBar now={Math.round(convertingProgress)} label={`converting...`} />
                ) : null}
                {status === statuses.UPLOADING ? (
                    <ProgressBar
                        now={Math.round(uploadingProgress)}
                        variant="warning"
                        label={"uploading..."}
                    />
                ) : null}
                {status === statuses.SUCCESS ? (
                    <Alert variant="success" style={{ textAlign: "center" }}>
                        Uploaded
                    </Alert>
                ) : null}
                {status === statuses.CONVERTING_FAILED ? (
                    <Button variant="danger" onClick={convertingFailedCallback} block>
                        Reconvert
                    </Button>
                ) : null}
                {status === statuses.UPLOADING_FAILED ? (
                    <Button variant="danger" onClick={uploadingFailedCallback} block>
                        Reupload
                    </Button>
                ) : null}
            </Card.Footer>
        </Card>
    );
}

export function AnimaticUploaderBadFileCard(props) {
    return (
        <Card>
            <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>{props.file.file.name}</Card.Title>
                <Card.Text style={{ textAlign: "center" }}>Sorry, this file is not valid</Card.Text>
            </Card.Body>
            <Card.Footer>
                <Alert variant="danger" style={{ textAlign: "center" }}>
                    Not valid
                </Alert>
            </Card.Footer>
        </Card>
    );
}
